import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    width: 60,
    customRender: (_, __, index) => index + 1,
  },
  {
    title: T("调整类型"),
    dataIndex: "type_display",
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
  },
  {
    title: T("容器编号"),
    dataIndex: "pallet_number",
  },
  {
    title: T("移出库位"),
    dataIndex: "location_out_number",
  },
  {
    title: T("移入库位"),
    dataIndex: "location_in_number",
  },
  {
    title: T("调整数量"),
    dataIndex: "adjust_quantity",
  },
];
