<template>
  <div>
    <a-card :title="$t('品质调整单详情')">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>
      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
          <a-descriptions bordered>
            <a-descriptions-item :label="$t('品质调整单号')">
              {{ qualityAdjustOrderItem.number }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('仓库')">
              {{ qualityAdjustOrderItem.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('状态')">
              {{ qualityAdjustOrderItem.is_void ? $t("已作废") : $t("已完成") }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('创建人')">
              {{ qualityAdjustOrderItem.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('创建时间')">
              {{ qualityAdjustOrderItem.create_time }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="qualityAdjustMaterialItems"
            :loading="materialLoading"
            :pagination="false"
            :scroll="{ x: 1020 }"
          >
          </a-table>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { qualityAdjustOrderRetrieve, qualityAdjustOrderMaterials } from "@/api/stockTransfer";
import { columns } from "./columns";

export default {
  data() {
    return {
      columns,
      orderLoading: false,
      materialLoading: false,
      qualityAdjustOrder: undefined,
      qualityAdjustOrderItem: {},
      qualityAdjustMaterialItems: [],
    };
  },
  methods: {
    initData() {
      this.qualityAdjustOrder = this.$route.query.id;
      this.orderLoading = true;
      document.getElementById("jsbarcode").html = "";
      qualityAdjustOrderRetrieve({ id: this.qualityAdjustOrder })
        .then((data) => {
          this.qualityAdjustOrderItem = data;
          new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.orderLoading = false;
        });

      this.materialLoading = true;
      qualityAdjustOrderMaterials({ id: this.qualityAdjustOrder })
        .then((data) => {
          this.qualityAdjustMaterialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style>
.jsbarcode {
  float: right;
}
</style>
